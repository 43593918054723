import React, {useCallback, useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CircleArrowRight from '../../resources/images/circle-arrow-right.svg';
import { Avatar, Grid } from '@mui/material';
import ConcentrationReportDialog from './../../dialogs/ConcentrationReportDialog';

interface Props {
    img: string;
    name: string;
    desc: string;
    link: string | undefined;
    bnpUserRol: string | null;
    azureToken: string | null;
    dqrUserRol: string | null;
    dataMarketPlaceRol: string | null;
    concentrateReportRol: string | null;
}

function DashboardCard({
                           img,
                           name,
                           desc,
                           link,
                           bnpUserRol,
                           azureToken,
                           dqrUserRol,
                           dataMarketPlaceRol,
                           concentrateReportRol
                       }: Props) {
    const [isDialogOpenn, setIsDialogOpenn] = useState(false);
    interface Roles {
        azureToken: string | null;
        bnpUserRol: string | null;
        dqrUserRol: string | null;
        dataMarketPlaceRol: string | null;
        concentrateReportRol: string | null;
    }

    const hasPermission = (name: string, roles: Roles) => {
        switch (name) {
            case 'Transaction Monitoring':
                return Boolean(roles?.azureToken);
            case 'BNP Report':
                return Boolean(roles?.bnpUserRol);
            case 'DQR':
                return Boolean(roles?.dqrUserRol);
            case 'Concentration Report':
                return Boolean(roles?.concentrateReportRol);
            case 'Data Marketplace':
                return Boolean(roles?.dataMarketPlaceRol);
            default:
                return false;
        }
    }
    const userHasPermission = hasPermission(name, { azureToken, bnpUserRol, dqrUserRol, dataMarketPlaceRol, concentrateReportRol });

    const handleCardClick = useCallback(() => {
        if (hasPermission(name, { azureToken, bnpUserRol, dqrUserRol, dataMarketPlaceRol, concentrateReportRol })) {
            if (name === 'Concentration Report') {
                setIsDialogOpenn(true);
            } else if (link) {
                window.location.href = link;
            }
        }
    }, [userHasPermission, name, link, setIsDialogOpenn]);

    const handleClose = () => {
        setTimeout(() => {
            setIsDialogOpenn(false);
        }, 10);
    };

    const validOptionsCard = useCallback(() => {
        let avatarStyle: React.CSSProperties = { width: 24, height: 24 };
        let validText = null;

        if (!userHasPermission) {
            avatarStyle = { ...avatarStyle, filter: "grayscale(100%)" };
            validText = <Typography style={{ marginRight: '8px' }}>You have no role for this application </Typography>;
        }

        return {
            validComponent: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {validText}
                    <Avatar
                        alt='circle-arrow-right'
                        src={CircleArrowRight}
                        sx={avatarStyle}
                    />
                </div>
            ),
        };
    }, [userHasPermission]);

    return (
        <Card sx={{ maxWidth: 345, cursor: userHasPermission ? 'pointer' : 'default' }} onClick={handleCardClick}>
            <CardMedia
                component='img'
                alt='dashboard-img'
                height='140'
                image={img}
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant='h5'
                    component='article'
                    color='black'
                    fontWeight='bold'
                >
                    {name}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                    {desc}
                </Typography>
            </CardContent>
            <CardActions>
                <Grid container justifyContent='flex-end'>
                    <Grid item>{validOptionsCard().validComponent}</Grid>
                </Grid>
            </CardActions>
            <ConcentrationReportDialog
                open={isDialogOpenn}
                onClose={() => handleClose()}
            />
        </Card>
    );
}

export default DashboardCard;