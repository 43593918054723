import {Box, Grid} from '@mui/material';
import CustomTable from '../../components/custom-table/custom-table';
import React, {useEffect, useMemo, useState} from "react";
import TextInput from "../../components/text-input/text-input";
import {useForm} from "react-hook-form";
import Loading from '../loading/loading';
import Typography from "@mui/material/Typography";

function Datafields() {
    const { control, watch } = useForm();
    const [selectedOwnership, setSelectedOwnership] = useState<OwnershipType | null>(null);
    const [selectedSchema, setSelectedSchema] = useState<SchemaType[]>([]);
    const [selectedTable, setSelectedTable] = useState<string>();
    const [data, setData] = useState<RowType[]>([]);
    const searchValue = watch('searchValue');
    const ownershipSearchValue = watch('ownershipSearchValue');
    useEffect(() => {
        const fetchData = async () => {
            const jsonData = await fetch('../../alldata.json').then((res) => res.json());
            setData(jsonData);
        };
        fetchData();
    }, []);

    const columns = useMemo(() => [
        { field: 'FIELD_NAME', headerName: 'Name' },
        { field: 'FIELD_TYPE', headerName: 'Type' },
        { field: 'FIELD_DESC', headerName: 'Description' },
        { field: 'TABLE_NAME', headerName: 'Table' },
        { field: 'PRIMARY_KEYS_COL', headerName: 'PrimaryKeys' },
        { field: 'DT_LOAD', headerName: 'Load' },
        { field: 'PIPELINE_NAME', headerName: 'Pipeline' },
        { field: 'INGESTION_TYPE', headerName: 'Ingestion' },
        { field: 'LOAD_TYPE', headerName: 'LoadType' },
        { field: 'BUSINESS_DESCRIPTION', headerName: 'BusinessDescription' },
    ], []);

    const summaryColumns = [
        { field: 'TABLE_NAME', headerName: 'Table' },
        { field: 'TABLE_TYPE', headerName: 'Type' },
        { field: 'PRIMARY_KEYS_COL', headerName: 'Primary_Keys' },
        { field: 'BUSINESS_DESCRIPTION', headerName: 'Description' },
        { field: 'DATASET_NAME', headerName: 'Dataset' },
    ];

    const schemaColumns = [
        { field: 'name', headerName: 'Field Name' },
        { field: 'type', headerName: 'Type' },
        { field: 'mode', headerName: 'Mode' },
        { field: 'description', headerName: 'Description' },
    ];

    type OwnershipType = {
        APPLICATION: string;
        APP_OWNER: string;
        DATA_OWNER: string;
        DELIVERY_SERVICE: string;
        FILES_PER_DAY: number;
        FUNCTIONAL_DOMAIN: string;
        HOST_TABLE_NAME: string;
        JOB_NAME: string;
        JOB_OWNER: string;
        SOURCE: string;
    };

    type RowType = {
        id: number;
        FIELD_NAME: string;
        FIELD_TYPE: string;
        FIELD_DESC: string | null;
        TABLE_NAME: string;
        PRIMARY_KEYS_COL: string;
        PIPELINE_NAME: string;
        INGESTION_TYPE: string;
        LOAD_TYPE: string;
        BUSINESS_DESCRIPTION: string | null;
        OWNERSHIP: OwnershipType;
        SCHEMA: SchemaType[];
        TABLE_TYPE: string | null;
        DATASET_NAME: string | null;
        PROJECT_ID: string | null;
    };

    type SchemaType = {
        description: string;
        mode: string;
        name: string;
        type: string;
    };

    const rows = (data as RowType[]).map((item: RowType, index: number) => ({
        ...item,
        id: index,
        PRIMARY_KEYS_COL: Array.isArray(item.PRIMARY_KEYS_COL) ? item.PRIMARY_KEYS_COL.join(", ") : item.PRIMARY_KEYS_COL,
        OWNERSHIP: item.OWNERSHIP,
        SCHEMA: item.SCHEMA as SchemaType[]
    }));

    const groupedSummaryRows = useMemo(() => {
        const uniqueMap = new Map<string, RowType>();
        rows.forEach((row: RowType) => {
            if (!uniqueMap.has(row.TABLE_NAME)) {
                uniqueMap.set(row.TABLE_NAME, row);
            }
        });
        return Array.from(uniqueMap.values()).map((row) => ({
            id: row.id,
            TABLE_NAME: row.TABLE_NAME,
            TABLE_TYPE: row.TABLE_TYPE,
            PRIMARY_KEYS_COL: row.PRIMARY_KEYS_COL,
            BUSINESS_DESCRIPTION: row.BUSINESS_DESCRIPTION,
            DATASET_NAME: row.DATASET_NAME,
            PROJECT_ID: row.PROJECT_ID,
            OWNERSHIP: JSON.stringify(row.OWNERSHIP),
            SCHEMA: JSON.stringify(row.SCHEMA)
        }));
    }, [rows]);

    const filteredRows = groupedSummaryRows.filter((row) => {
        const schema = JSON.parse(row.SCHEMA) as SchemaType[];

        const schemaMatch = searchValue
            ? schema.some((field) =>
                field.name.toLowerCase().includes(searchValue.toLowerCase())
            )
            : true;

        const ownership = JSON.parse(row.OWNERSHIP) as OwnershipType;
        const ownershipMatch = ownershipSearchValue
            ? (ownership.DATA_OWNER.toLowerCase().includes(ownershipSearchValue.toLowerCase()) ||
                ownership.APP_OWNER.toLowerCase().includes(ownershipSearchValue.toLowerCase()))
            : true;

        return schemaMatch && ownershipMatch;
    });


    const handleRowClick = (rowId: number) => {
        const selectedRow = filteredRows.find((row) => row.id === rowId);
        if (selectedRow) {
            setSelectedOwnership(JSON.parse(selectedRow.OWNERSHIP));
            setSelectedSchema(JSON.parse(selectedRow.SCHEMA));
            setSelectedTable(selectedRow.TABLE_NAME);
        }
    };

    document.title = `AppHub - Data Marketplace`;
    return (
        <Box
            sx={{
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <Grid item sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: -2, marginTop: -1 }}>
                    <TextInput
                        name='searchValue'
                        control={control}
                        labelName={'Search by field name'}
                    />
                    <TextInput
                        name='ownershipSearchValue'
                        control={control}
                        labelName={'Search by owner'}
                    />
                </Box>
                <CustomTable
                    rows={filteredRows}
                    columns={summaryColumns}
                    useCheckbox={false}
                    onRowClick={handleRowClick}
                    enableSearchByHead={true}
                    defaultRowsPerPage={5}
                />
            </Grid>
            {selectedOwnership && (
                <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                    <Grid item xs={4}>
                        <Box
                            sx={{
                                padding: '10px 20px',
                                border: '1px solid #ddd',
                                boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                                borderRadius: '8px',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="h6" gutterBottom sx={{ borderBottom: '1px solid #ddd', paddingBottom: '5px' }}>
                                Ownership Details
                            </Typography>
                            <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', rowGap: '8px', columnGap: '16px', paddingTop: '10px' }}>
                                <Typography><strong>Application:</strong></Typography>
                                <Typography>{selectedOwnership.APPLICATION}</Typography>

                                <Typography><strong>App Owner:</strong></Typography>
                                <Typography>{selectedOwnership.APP_OWNER}</Typography>

                                <Typography><strong>Data Owner:</strong></Typography>
                                <Typography>{selectedOwnership.DATA_OWNER}</Typography>

                                <Typography><strong>Delivery Service:</strong></Typography>
                                <Typography>{selectedOwnership.DELIVERY_SERVICE}</Typography>

                                <Typography><strong>Files per Day:</strong></Typography>
                                <Typography>{selectedOwnership.FILES_PER_DAY}</Typography>

                                <Typography><strong>Functional Domain:</strong></Typography>
                                <Typography>{selectedOwnership.FUNCTIONAL_DOMAIN}</Typography>

                                <Typography><strong>Host Table Name:</strong></Typography>
                                <Typography>{selectedOwnership.HOST_TABLE_NAME}</Typography>

                                <Typography><strong>Job Name:</strong></Typography>
                                <Typography>{selectedOwnership.JOB_NAME}</Typography>

                                <Typography><strong>Job Owner:</strong></Typography>
                                <Typography>{selectedOwnership.JOB_OWNER}</Typography>

                                <Typography><strong>Source:</strong></Typography>
                                <Typography>{selectedOwnership.SOURCE}</Typography>
                            </Box>
                        </Box>

                    </Grid>
                    <Grid item xs={8}>
                        <Box
                            sx={{
                                padding: '10px',
                                border: '1px solid #ddd',
                                boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                                borderRadius: '8px',
                                height: '100%',
                                backgroundColor: 'background.paper'
                            }}
                        >
                            <Typography sx={{marginBottom: '-15px'}} variant="h6" gutterBottom>Schema details for table: {selectedTable}</Typography>
                            <CustomTable
                                rows={selectedSchema}
                                columns={schemaColumns}
                                useCheckbox={false}
                                defaultRowsPerPage={5}
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

export default Datafields;
