import { Box, Grid } from '@mui/material';
import DashboardCard from '../../components/dashboard-card/dashboard-card';
import { data } from '../../data/dashboard/data-for-card';
import { useStore } from '../../store';

function Dashboard() {
    document.title = `AppHub - Dashboard`;
    const [azureToken, bnpUserRol, dqrUserRol, dataMarketPlaceRol, concentrateReportRol] = useStore((state) => [
        state.azureToken,
        state.bnpUserRol,
        state.dqrUserRol,
        state.dataMarketPlaceRol,
        state.concentrateReportRol
    ]);

    return (
        <Box>
            <Grid
                container
                gap={2}
                sx={{
                    justifyContent: {
                        xs: 'center',
                        sm: 'center',
                        md: 'flex-start',
                        lg: 'flex-start',
                    },
                }}
            >
                {data.map(({ img, name, desc, link }) => {
                    return (
                        <Grid item key={name}>
                            <DashboardCard
                                img={img}
                                name={name}
                                desc={desc}
                                link={link}
                                bnpUserRol={bnpUserRol}
                                azureToken={azureToken}
                                dqrUserRol={dqrUserRol}
                                dataMarketPlaceRol={dataMarketPlaceRol}
                                concentrateReportRol={concentrateReportRol}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}

export default Dashboard;
