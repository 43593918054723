import React from 'react';
import { CustomTableProps } from '../../types/custom-table';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useCallback, useMemo, useState } from 'react';
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { ColumnsName, Rows } from '../../types/shared';
import { Button, Checkbox, Grid } from '@mui/material';
import CustomTableHead from './head/custom-table-head';
import ChipStatus from '../chip-status/chip-status';
import {useForm} from "react-hook-form";

type Order = 'asc' | 'desc';
type T = ColumnsName;

enum OrderByColumn {
    dateFrom = 'dateFrom',
    dateTo = 'dateTo',
    generated = 'generated',
}

const CustomTable: React.FC<CustomTableProps> = ({
    rows,
    columns,
    useCheckbox,
    selected = [],
    setSelected,
    handleNavigate,
    handleChipStatus,
    transformInUnixValue,
    groupType,
    groupId,
    actionsData,
    isSelectable,
    onRowClick,
    defaultRowsPerPage = 10,
    enableSearchByHead = false
}) => {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState<keyof ColumnsName>('generated');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);


    const { control, watch } = useForm();
    const searchTerms = watch();

    const descendingComparator = useCallback((a: T, b: T, orderBy: keyof T) => {
        const valueA = a[orderBy];
        const valueB = b[orderBy];

        if (valueA === undefined || valueB === undefined) {
            return 0;
        }

        if (valueB < valueA) {
            return -1;
        }
        if (valueB > valueA) {
            return 1;
        }
        return 0;
    }, []);

    const getComparator = useMemo(() => {
        const dateColumns = [
            OrderByColumn.dateFrom,
            OrderByColumn.dateTo,
            OrderByColumn.generated,
        ];

        return <Key extends keyof ColumnsName>(
            order: Order,
            orderBy: Key
        ): ((a: ColumnsName, b: ColumnsName) => number) => {
            return order === 'desc'
                ? (a, b) => {
                      if (dateColumns.includes(orderBy as OrderByColumn)) {
                          const timestampA = transformInUnixValue
                              ? transformInUnixValue(a[orderBy] ?? '')
                              : 100;
                          const timestampB = transformInUnixValue
                              ? transformInUnixValue(b[orderBy] ?? '')
                              : 200;
                          return timestampB - timestampA;
                      } else {
                          return descendingComparator(a, b, orderBy);
                      }
                  }
                : (a, b) => {
                      if (dateColumns.includes(orderBy as OrderByColumn)) {
                          const timestampA = transformInUnixValue
                              ? transformInUnixValue(a[orderBy] ?? '')
                              : 100;
                          const timestampB = transformInUnixValue
                              ? transformInUnixValue(b[orderBy] ?? '')
                              : 200;
                          return timestampA - timestampB;
                      } else {
                          return -descendingComparator(a, b, orderBy);
                      }
                  };
        };
    }, [descendingComparator, transformInUnixValue]);

    const handleRequestSort = useCallback(
        (property: keyof ColumnsName) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        },
        [order, orderBy]
    );

    const handleSelectAllClick = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                const newSelected = rows.map(({ id }) => id);
                if (setSelected) {
                    setSelected(newSelected as GridRowSelectionModel);
                }
                return;
            }
            if (setSelected) {
                setSelected([]);
            }
        },
        [rows, setSelected, useCheckbox]
    );

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLTableRowElement>, id: GridRowId) => {
            if ((useCheckbox || isSelectable) && setSelected && selected) {
                const selectedIndex = selected.indexOf(id);
                let newSelected: GridRowSelectionModel = [];

                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(selected, id);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(selected.slice(1));
                } else if (selectedIndex === selected.length - 1) {
                    newSelected = newSelected.concat(selected.slice(0, -1));
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                        selected.slice(0, selectedIndex),
                        selected.slice(selectedIndex + 1)
                    );
                }

                setSelected(newSelected);
            }
        },
        [selected, setSelected, useCheckbox, isSelectable]
    );

    const handleChangePage = useCallback((event: unknown, newPage: number) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        },
        []
    );

    const isSelected = useCallback(
        (id: string) => {
            return selected.indexOf(id as string) !== -1;
        },
        [selected]
    );

    const stableSort = useCallback(
        (array: readonly Rows[], comparator: (a: T, b: T) => number) => {
            const stabilizedThis = array.map(
                (el, index) => [el, index] as [T, number]
            );

            stabilizedThis.sort((a, b) => {
                const order = comparator(a[0], b[0]);

                if (order !== 0) {
                    return order;
                }
                return a[1] - b[1];
            });

            return stabilizedThis.map((el) => el[0]);
        },
        []
    );

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const { filteredRows, visibleRows } = useMemo(() => {
        const filteredRows = rows.filter((row) => {
            return Object.keys(searchTerms).every((key) => {
                const searchValue = searchTerms[key]?.toLowerCase();
                const rowValue = row[key as keyof ColumnsName]?.toString().toLowerCase();
                return searchValue ? rowValue?.includes(searchValue) : true;
            });
        });

        const sortedRows = stableSort(
            filteredRows,
            getComparator((order as 'asc') || 'desc', orderBy)
        );

        const visibleRows = sortedRows.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );

        return { filteredRows, visibleRows };
    }, [rows, searchTerms, getComparator, order, orderBy, page, rowsPerPage, stableSort]);

    const memoizedCustomTableHead = useMemo(() => (
        <CustomTableHead
            numSelected={selected ? selected.length : 0}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            columns={columns}
            useCheckbox={useCheckbox}
            control={control}
            enableSearch={enableSearchByHead}
        />
    ), [selected.length, order, orderBy, rows.length, columns, useCheckbox, control, enableSearchByHead]);

    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer sx={{ overflowX: 'hidden',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.12)',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                backgroundColor: 'background.paper',
                marginTop:'20px' }}>
                <Table aria-labelledby='tableTitle'>
                    {memoizedCustomTableHead}
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            const isItemSelected = isSelected(row.id as string);
                            const labelId = `enhanced-table-checkbox-${row.id}`;

                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => {
                                        handleClick(event, row.id as GridRowId);
                                        if (onRowClick) {
                                            onRowClick(row.id as GridRowId);
                                        }
                                    }}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={`${row.id}-${index}`}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {useCheckbox && (
                                        <TableCell
                                            padding='checkbox'
                                            width='5%'
                                        >
                                            <Checkbox
                                                color='primary'
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                    )}

                                    {columns.map((column) => {
                                        const value =
                                            row[
                                                column.field as keyof ColumnsName
                                            ];

                                        return (
                                            <TableCell
                                                key={column.field}
                                                onClick={() => {
                                                    if (handleNavigate && column.field !== 'action') {
                                                        handleNavigate(row.id || row.isin, row.type);
                                                    } else if (onRowClick) {
                                                        onRowClick(row.id as GridRowId);
                                                    }
                                                }}
                                            >
                                                {column.field === 'status' ? (
                                                    <ChipStatus
                                                        label={
                                                            handleChipStatus && handleChipStatus(value ?? '')?.label
                                                        }
                                                        color={
                                                            handleChipStatus && handleChipStatus(value ?? '')?.color
                                                        }
                                                    />
                                                ) : (
                                                    value
                                                )}

                                                {column.field === 'action' && (
                                                    <Grid
                                                        container
                                                        rowGap={1}
                                                        columnGap={1}
                                                    >
                                                        {actionsData?.map(
                                                            ({
                                                                status,
                                                                color,
                                                                buttonText,
                                                                actionType,
                                                                actionEvent,
                                                            }) => (
                                                                <Grid
                                                                    item
                                                                    key={
                                                                        buttonText
                                                                    }
                                                                >
                                                                    <Button
                                                                        disabled={
                                                                            row.status ===
                                                                            status
                                                                        }
                                                                        variant='contained'
                                                                        color={
                                                                            color as
                                                                                | 'warning'
                                                                                | 'success'
                                                                        }
                                                                        size='small'
                                                                        sx={{
                                                                            fontWeight:
                                                                                'bold',
                                                                        }}
                                                                        onClick={() => {
                                                                            if (
                                                                                actionType ===
                                                                                    'reportDetailSendAction' &&
                                                                                actionEvent
                                                                            ) {
                                                                                actionEvent(
                                                                                    row.id,
                                                                                    groupId as string,
                                                                                    groupType as string,
                                                                                    row.providerName
                                                                                );
                                                                            } else if (
                                                                                actionType ===
                                                                                    'reportDetailDownloadAction' &&
                                                                                actionEvent
                                                                            ) {
                                                                                actionEvent(
                                                                                    row.id
                                                                                );
                                                                            } else if (
                                                                                actionType ===
                                                                                    'reportListAction' &&
                                                                                actionEvent
                                                                            ) {
                                                                                actionEvent(
                                                                                    row.id,
                                                                                    row.type
                                                                                );
                                                                            } else if (
                                                                                actionType ===
                                                                                    'fileUploadEraseAction' &&
                                                                                actionEvent
                                                                            ) {
                                                                                actionEvent(
                                                                                    row.id,
                                                                                    row.type
                                                                                );
                                                                            } else if (
                                                                                actionType ===
                                                                                    'isinEraseAction' &&
                                                                                actionEvent
                                                                            ) {
                                                                                actionEvent(
                                                                                    row.id,
                                                                                    row.type
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        {
                                                                            buttonText
                                                                        }
                                                                    </Button>
                                                                </Grid>
                                                            )
                                                        )}
                                                    </Grid>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={8} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
            />
        </Box>
    );
};

export default CustomTable;
