import React, { createContext, useCallback, useContext, useState } from 'react';
import { AlertColor } from '@mui/material';
import { useStore } from '../store';
import { useNavigate } from 'react-router-dom';
import { UIControlContext } from '../types/ui-control-context';

const context = createContext<UIControlContext | undefined>(undefined);

export type UIControlContextProviderProps = UIControlContext & {
    children: React.ReactElement | null;
};

export function UIControlContextProvider(
    props: UIControlContextProviderProps
): React.ReactElement {
    const navigate = useNavigate();
    const [
        setAccessToken,
        setAzureToken,
        setBnpUserRol,
        setDqrUserRol,
        setDataMarketPlaceRol,
        setConcentrateReportRol,
        groupNameUnique,
        setGroupNameUnique,
        setGroupId,
        setGroupType,
        setTypeId,
    ] = useStore((state) => [
        state.setAccessToken,
        state.setAzureToken,
        state.setBnpUserRol,
        state.setDqrUserRol,
        state.setDataMarketPlaceRol,
        state.setConcentrateReportRol,
        state.groupNameUnique,
        state.setGroupNameUnique,
        state.setGroupId,
        state.setGroupType,
        state.setTypeId,
    ]);
    const [reportDetailId, setReportDetailId] = useState<string | undefined>(
        ''
    );
    const [errorMessage, setErrorMessage] = useState<string | undefined>('');
    const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>(
        undefined
    );
    const [alertMessage, setAlertMessage] = useState<string | undefined>(
        undefined
    );
    const [openToast, setOpenToast] = useState<boolean | undefined>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean | undefined>(
        false
    );
    const [loading, setLoading] = useState<boolean | undefined>(false);
    const [refetchData, setRefetchData] = useState(false);

    const handleOpenDialog = useCallback(() => {
        setIsDialogOpen(true);
    }, [setIsDialogOpen]);

    const handleCloseDialog = useCallback(() => {
        setIsDialogOpen(false);
    }, [setIsDialogOpen]);

    const handleOpenToast = useCallback(
        (url?: string): void | undefined => {
            setOpenToast(true);

            if (url) {
                window.open(url);
            }
        },
        [setOpenToast]
    );

    const handleManageToast = useCallback(
        (alertSeverity: AlertColor, alertMessage: string, fileUrl?: string) => {
            setAlertSeverity(alertSeverity);
            setAlertMessage(alertMessage);
            handleOpenToast(fileUrl);
        },
        [setAlertSeverity, setAlertMessage, handleOpenToast]
    );

    const handleCloseToast = useCallback((): void | undefined => {
        setOpenToast(false);
    }, [setOpenToast]);

    const [openBackdrop, setOpenBackdrop] = useState<boolean | undefined>(
        false
    );

    const handleOpenBackdrop = useCallback((): void | undefined => {
        setOpenBackdrop(true);
    }, [setOpenBackdrop]);

    const handleCloseBackdrop = useCallback((): void | undefined => {
        setOpenBackdrop(false);
    }, [setOpenBackdrop]);

    const handleLogOut = useCallback(() => {
        setAccessToken(null);
        setAzureToken(null);
        setBnpUserRol(null);
        setDqrUserRol(null);
        setDataMarketPlaceRol(null);
        setConcentrateReportRol(null)
        setGroupNameUnique(undefined);
        setGroupId(undefined);
        setGroupType(undefined);
        setTypeId(undefined);
        navigate('/');
    }, [
        navigate,
        setAccessToken,
        setAzureToken,
        setBnpUserRol,
        setDqrUserRol,
        setDataMarketPlaceRol,
        setConcentrateReportRol,
        setGroupNameUnique,
        setGroupId,
        setGroupType,
        setTypeId,
    ]);

    const handleNavigateToProviderList = useCallback(
        (
            groupId: string,
            groupType: string,
            groupName?: string,
            reportDetailId?: string
        ) => {
            navigate(`/bnp/reports-list/${groupId}:${groupType}/send`);

            if (groupName && reportDetailId) {
                setGroupNameUnique(groupName);
                setReportDetailId(reportDetailId);
            } else {
                setGroupNameUnique(undefined);
                setReportDetailId(undefined);
            }
        },
        [navigate, setGroupNameUnique, setReportDetailId]
    );

    return (
        <context.Provider
            value={{
                alertSeverity,
                alertMessage,
                setAlertMessage,
                openToast,
                setOpenToast,
                handleCloseToast,
                openBackdrop,
                setOpenBackdrop,
                handleOpenBackdrop,
                handleCloseBackdrop,
                handleLogOut,
                handleNavigateToProviderList,
                groupNameUnique,
                reportDetailId,
                isDialogOpen,
                setIsDialogOpen,
                handleOpenDialog,
                handleCloseDialog,
                handleManageToast,
                loading,
                setLoading,
                errorMessage,
                setErrorMessage,
                refetchData,
                setRefetchData,
            }}
        >
            {props.children}
        </context.Provider>
    );
}

export function useUIControlContext(): UIControlContext {
    const globalContext = useContext(context);

    if (globalContext === undefined) {
        throw new Error(
            'You must use `useUIControlContext` hook within a component that is a descendant of a <UIControlContextProvider />'
        );
    }

    return globalContext;
}
